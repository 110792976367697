@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Light.woff2') format('woff2'),
    url('../fonts/Inter-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-LightItalic.woff2') format('woff2'),
    url('../fonts/Inter-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Regular.woff2') format('woff2'),
    url('../fonts/Inter-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Italic.woff2') format('woff2'),
    url('../fonts/Inter-Italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Medium.woff2') format('woff2'),
    url('../fonts/Inter-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-MediumItalic.woff2') format('woff2'),
    url('../fonts/Inter-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Bold.woff2') format('woff2'),
    url('../fonts/Inter-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-BoldItalic.woff2') format('woff2'),
    url('../fonts/Inter-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'FabricSerifWeb';
  src: url('../fonts/FabricSerifWeb-Regular.woff2') format('woff2'),
    url('../fonts/FabricSerifWeb-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
