@keyframes placeHolderShimmer {
  0% {
    background-position: -110% 0;
  }
  100% {
    background-position: 110% 0;
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translate3d(-200px, 0, 0);
  }
  to {
    opacity: 1;
    transform: rotate(0) scale(1);
  }
}

.slideIn {
  animation: slideIn 600ms cubic-bezier(0.23, 1, 0.32, 1) both;
}

@keyframes slideOut {
  from {
    opacity: 1;
    transform: rotate(0) scale(1);
  }
  to {
    opacity: 0;
    transform: translate3d(-100px, 0, 0px);
  }
}

.slideOut {
  animation: slideOut 600ms cubic-bezier(0.23, 1, 0.32, 1) both;
}
