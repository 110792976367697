a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
i,
em {
  font-style: normal;
}
b,
strong {
  font-weight: 400;
}

::-moz-selection {
  color: #fff;
  background: #000;
}
::selection {
  color: #fff;
  background: #000;
}

@media (prefers-color-scheme: dark) {
  ::-moz-selection {
    color: #000;
    background: #fff;
  }
  ::selection {
    color: #000;
    background: #fff;
  }
}

.hidden {
  opacity: 0;
  transition: opacity 400ms ease-in-out;
}

.lazy-load-image-background,
.lazy-load-image-background > img {
  display: block !important;
}

input[type='text']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type='text']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type='number'] {
  -moz-appearance: textfield;
}

*:focus {
  outline: none;
}
